import { Card } from 'react-bootstrap'

const Aside = ({
  title = 'More Info',
  image,
  children,
  text = (
    <p>
      <b>Strength training</b> or <b>resistance training</b> involves the
      performance of physical exercises which are designed to improve strength
      and endurance. It is often associated with the use of{' '}
      <a href="/wiki/Weight_training" title="Weight training">
        weights
      </a>{' '}
      but can take a variety of different forms.
    </p>
  ),
  ...rest
}) => (
  <Card title={title} as="aside" {...rest}>
    {image && (
      <Card.Img
        variant="top"
        src="https://upload.wikimedia.org/wikipedia/commons/d/dc/Small_group_fitness_sessions_bundall.jpg"
      />
    )}
    <Card.Body>
      {title && <Card.Title>{title}</Card.Title>}
      {text && <Card.Text as="div">{text}</Card.Text>}
      {children}
    </Card.Body>
  </Card>
)

export default Aside
