import SectionHeader from '../../_layouts/content/SectionHeader'
import Aside from '../../_layouts/content/Aside'
import LoginPrompt from '../../auth/components/LoginPrompt'

const GettingStarted = (s) => {
  return (
    <section>
      <SectionHeader
        documentTitle="Workout Generator - Create workouts with random exersises"
        title="Get Ready to Add Some Variety to Your Workouts"
      />
      <p className="lead">
        Are you tired of doing the same exercises for every workout? Have you
        spent hours looking online or in fitness magazines for new ideas, only
        to find that those ideas were difficult to incorporate into your new
        fitness routine?
      </p>
      <p className="lead">
        <em>Look no further!</em> Let the{' '}
        <strong>OutWorkIt Random Workout Generator</strong> create new workout
        plans for you.
      </p>
      <p className="lead">
        <strong>OutWorkIt</strong> selects random exercises based on your goals
        and preferences, allowing you to focus on the good stuff:{' '}
        <em>losing weight (cutting fat) and gaining muscle!</em>
      </p>
      <article className="row">
        <div className="col-md-9">
          <div className="float-md-left mr-md-3 pr-md-4 mb-3 mb-md-1">
            <img
              src={`${process.env.PUBLIC_URL}/marketing-images/workout-callout.jpg`}
              alt=""
              className="w-100"
            />
          </div>
          <h2 className="text-primary h5">Included:</h2>
          <ul>
            <li className="pb-3">
              A <strong>list of exercises</strong> targeting{' '}
              <strong>all of the muscle groups</strong> that you specify. These
              exercises are based on
              <strong> strength training, cardio, or both</strong>!{' '}
              <span className="text-muted">
                (Also, this list is pre-selected with some sensible defaults if
                you are new to workout planning).
              </span>
            </li>
            <li className="pb-3">
              Each exercise and associated muscle group is split into a
              customizable <strong>daily schedule</strong>.
            </li>
            <li className="pb-3">
              You can create and save <strong>multiple schedules</strong> and
              reuse them later in your fitness journey.
            </li>
            <li className="pb-3">
              Works for either <strong>home gym</strong> or a{' '}
              <strong>commercial gym</strong> --just tailor your preferences
              based on the equipment you have available.
            </li>
            <li>
              <strong>New features every month.</strong> We are always adding
              new stuff to make your workouts better. New features will include
              the ability to edit individual workouts, pin exercises, and log
              workout progress!
            </li>
          </ul>
        </div>
        <div className="col-md-3 mt-4 mt-md-0">
          <Aside
            title="Let's Go!"
            className="bg-light"
            text={
              <p>
                OutWorkIt is <strong>100% free</strong>. All you have to do is
                sign up/register with Google or Facebook and we will walk you
                through the process of creating your first workout. It only
                takes 5-10 minutes. What do you have to lose?
              </p>
            }
          >
            <LoginPrompt size="md" />
          </Aside>
        </div>
      </article>
      <figure className="mt-4">
        <SectionHeader heading="2" title="Example Workout:" />
        <img
          src={`${process.env.PUBLIC_URL}/marketing-images/sample-workout.png`}
          alt="example of workout"
          className="w-100 rounded shadow"
        />
      </figure>
    </section>
  )
}

export default GettingStarted
