import { useEffect } from 'react'
import classNames from 'classnames'

const SectionHeader = ({
  title,
  documentTitle,
  description,
  heading = 1,
  className,
  ...rest
}) => {
  useEffect(() => {
    if (documentTitle) document.title = `OutWorkIt - ${documentTitle}`
  }, [documentTitle])

  const Heading = `h${heading}`
  return (
    <div
      className={classNames(
        { 'mb-3': +heading !== 1 },
        { 'mb-3 mt-mb-5': +heading === 1 },
        className
      )}
      {...rest}
    >
      <Heading>{title}</Heading>
      {description && <p className="text-muted">{description} </p>}
    </div>
  )
}

export default SectionHeader
